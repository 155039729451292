









import { Component, Prop, Vue } from 'vue-property-decorator'
import IconBullhorn from '@/components/UI/icons/IconBullhorn.vue'
import IconHashtag from '@/components/UI/icons/IconHashtag.vue'
import IconLock from '@/components/UI/icons/IconLock.vue'
import { groupsStore } from '@/store'

@Component({ name: 'GroupIcon' })
export default class GroupIcon extends Vue {
  /**
   * Group jid to use for icon detection.
   * Not used if override-group is set to true.
   */
  @Prop({
    type: String,
    default: '',
  }) readonly jid!: string

  @Prop({
    type: String,
    required: false,
  }) readonly color!: string

  @Prop({
    type: String,
    default: 'xs',
  }) readonly size!: string

  /**
   * Boolean to override group detection from provided jid.
   * Without this prop read-only and is-public do nothing.
   * When enabled and above props not provided - returns default group icon.
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly overrideGroup!: boolean

  /**
   * Boolean to use for icon detection.
   * Only has effect when override-group is enabled.
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly readOnly!: boolean

  /**
   * Boolean to use for icon detection.
   * Only has effect when override-group is enabled.
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly isPublic!: boolean

  iconBullhorn!: any
  iconHashtag!: any
  iconLock!: any

  created () {
    this.iconHashtag = IconHashtag
    this.iconLock = IconLock
    this.iconBullhorn = IconBullhorn
  }

  get icon () {
    let readOnly = false
    let isPublic = false

    if (this.overrideGroup) {
      readOnly = this.readOnly
      isPublic = this.isPublic
    } else {
      const group = groupsStore.state.data[this.jid]
      if (!group) return this.iconHashtag
      readOnly = group.readonlyForMembers ?? false
      isPublic = group.isPublic ?? false
    }

    if (readOnly) return this.iconBullhorn
    if (isPublic) return this.iconHashtag

    return this.iconLock
  }
}
